import React, { createContext, useReducer, useContext } from "react"
import PropTypes from "prop-types"

const UPDATE_CURRENT_SCREEN = "update-current-screen"

const initialState = { currentScreen: 0 }

const ChaptersContext = createContext()

function updateChaptersReducer(state, action) {
  switch (action.type) {
    case UPDATE_CURRENT_SCREEN: {
      const reducedState = { ...state, currentScreen: action.newScreen }
      return reducedState
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

function ChaptersProvider({ children }) {
  const [state, dispatch] = useReducer(updateChaptersReducer, initialState)

  const value = {
    ...state,
    updateCurrentScreen: newScreen => {
      dispatch({ type: UPDATE_CURRENT_SCREEN, newScreen })
    },
  }

  return (
    <ChaptersContext.Provider value={value}>
      {children}
    </ChaptersContext.Provider>
  )
}

ChaptersProvider.propTypes = {
  children: PropTypes.node,
}

function useChaptersContext() {
  const context = useContext(ChaptersContext)
  if (context === undefined) {
    throw new Error("useChaptersContext must be used within a ChaptersProvider")
  }
  return context
}

export {
  ChaptersContext,
  ChaptersProvider,
  updateChaptersReducer,
  useChaptersContext,
}
