import { configureStore } from "@reduxjs/toolkit"
import chaptersReducer from "./reducers/chapters.reducer"

export const store = configureStore({
  reducer: {
    chapters: chaptersReducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
