import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit"
import axios from "axios"
import { GET_CHAPTERS } from "../../constants/api"

interface Chapter {
  __typename: string
  chapterName: string
  updatedAt: string
  createdAt: string
  pnmembersID: string
  meetingDay: string
  id: string
  meetingTime: string
}

// Define the initial state
export interface AppState {
  chapters: Chapter[]
  status: string
  error: string | null
  currentScreen: number
}

const initialState: AppState = {
  chapters: [],
  status: "idle",
  error: null,
  currentScreen: 0,
}

// Thunk to fetch chapters
export const fetchChapters = createAsyncThunk(
  "chapters/fetchChapters",
  async () => {
    const response = await axios.get(GET_CHAPTERS)
    return response.data
  }
)

export const chaptersSlice = createSlice({
  name: "chapters",
  initialState,
  reducers: {
    updateCurrentScreen: (state, action: PayloadAction<number>) => {
      state.currentScreen = action.payload
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchChapters.pending, state => {
        state.status = "loading"
      })
      .addCase(fetchChapters.fulfilled, (state, action) => {
        state.status = "succeeded"
        state.chapters = action.payload
      })
      .addCase(fetchChapters.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
      })
  },
})

export const { updateCurrentScreen } = chaptersSlice.actions

export default chaptersSlice.reducer
