const primaryColor = "#8cc63f"
const secondaryColor = "#00aeef"
const accentColor = "#fff"

const appTitle = "Preferred Networking"
const metaDescription =
  "Preferred Networking is a virtual networking organization with multiple chapters that meet weekly, online. "
const metaKeywords = "leads, networking, virtual networking"

const googleFonts =
  "https://fonts.googleapis.com/css2?family=Open+Sans&family=Questrial&display=swap"

const fontFamilies = "'Open Sans', 'Questrial', sans-serif"
const headlineFont = "'Questrial', sans-serif"
const bodyFont = "'Open Sans', sans-serif"

const headlineFontStyles = {
  fontFamily: headlineFont,
  fontWeight: 800,
  fontStyle: "normal",
  letterSpacing: "-0.02em",
}

const commonColorStyles = {
  white: "#fff",
  black: "#000",
  grey: "#696a6c",
  lightGrey: "#F7F9FB",
}

const customColorStyles = {
  snow: {
    primary: "#fff",
    main: "#fff",
  },
  primaryAlpha: {
    main: "rgba(141, 198, 63, 0.5)",
  },
}

export {
  googleFonts,
  primaryColor,
  secondaryColor,
  accentColor,
  fontFamilies,
  headlineFont,
  headlineFontStyles,
  commonColorStyles,
  bodyFont,
  metaDescription,
  metaKeywords,
  appTitle,
  customColorStyles,
}
